@tailwind base;
@tailwind components;
@tailwind utilities;

@import "https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900";

html, body {
  margin: 0;
  height: 100%!important;
  background-color: #101316;
  font-family: Poppins, sans-serif;
}
.home-hero {
  background: rgb(17,19,45);
  background: linear-gradient(180deg, rgba(17,19,45,1) 0%, rgba(16,19,22,1) 99%);
}
.hero-rust {
  background-color: #101316;
  background-image: linear-gradient(
    to bottom, transparent, #101316
  ), url("./assets/images/backgrounds/rust.webp");
  background-repeat: no-repeat;
  background-size: cover;
}
.hero-ark {
  background-color: #101316;
  background-image: linear-gradient(
    to bottom, transparent, #101316
  ), url("./assets/images/backgrounds/ark.webp");
  background-repeat: no-repeat;
  background-size: cover;
}
.hero-garrysmod {
  background-color: #101316;
  background-image: linear-gradient(
    to bottom, transparent, #101316
  ), url("./assets/images/backgrounds/garrysmod.webp");
  background-repeat: no-repeat;
  background-size: cover;
}
.hero-hytale {
  background-color: #101316;
  background-image: linear-gradient(
    to bottom, transparent, #101316
  ), url("./assets/images/backgrounds/hytale.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 5%;
}
.hero-arma {
  background-color: #101316;
  background-image: linear-gradient(
    to bottom, transparent, #101316
  ), url("./assets/images/backgrounds/arma.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 5%;
}
.hero-csgo {
  background-color: #101316;
  background-image: linear-gradient(
    to bottom, transparent, #101316
  ), url("./assets/images/backgrounds/csgo.webp");
  background-repeat: no-repeat;
  background-size: cover;
}
.hero-valheim {
  background-color: #101316;
  background-image: linear-gradient(
    to bottom, transparent, #101316
  ), url("./assets/images/backgrounds/valheim.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 5%;
}
.bg-minecraft {
  background-image: url('./assets/images/backgrounds/games/minecraft.webp');
}
.bg-ark {
  background-image: url('./assets/images/backgrounds/games/ark.webp');
}
.bg-rust {
  background-image: url('./assets/images/backgrounds/games/rust.webp');
}
.bg-arma {
  background-image: url('./assets/images/backgrounds/games/arma.webp');
}
.bg-csgo {
  background-image: url('./assets/images/backgrounds/games/csgo.webp');
}
.bg-garrysmod {
  background-image: url('./assets/images/backgrounds/games/garrysmod.webp');
}
.bg-hytale {
  background-image: url('./assets/images/backgrounds/games/hytale.webp');
}
.bg-valheim {
  background-image: url('./assets/images/backgrounds/games/valheim.webp');
}
.primary-gradient {
  background: rgb(17,19,45);
  background: linear-gradient(180deg, rgba(17,19,45,1) 50%, rgba(16,19,22,1) 90%);
}
.secondary-gradient {
  background: rgb(17,19,45);
  background: linear-gradient(180deg, rgba(34, 39, 88) 50%, rgba(26, 32, 67) 90%);
}
.left-gradient {
  background: rgb(17,19,45);
  background: linear-gradient(270deg, rgba(17,19,45,1) 65%, rgba(16, 19, 25,1) 95%);
}
.plans .hosting-plan {
  border-right: 1px solid rgba(55, 65, 81);
}
.plans .popular-plans .hosting-plan.no-border {
  border-right: none!important;
}
@media(max-width: 1024px) {
  .plans .popular-plans .hosting-plan {
    border-right: none!important;
    border-bottom: 1px solid rgba(55, 65, 81);
  }
  .plans .popular-plans .hosting-plan.no-border {
    border-bottom: none!important;
  }
}
.games .search input::placeholder {
  color: rgb(209 213 219);
}